import React, { useCallback,useState, useEffect, useContext} from "react";
import {UserContext} from "../App";
import {MainButton, useWebApp} from "@vkruglikov/react-telegram-web-app";


export default function Projects(props) {
 console.log(props.start);
  const webapp = useWebApp();
  const openEx = (link)=>{

webapp.openLink(link);

  }
  async function fetchMeData(pro) {
    const response = await fetch("https://timesca.com/wp-json/wp/v2/media?search=poto&per_page=100");
    await response.json().then(
      a => {
        setArtsMy(a);
      })
  }
    async function fetchUserData(pro) {
  const response = await fetch("https://timesca.com/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,link,featured_media&per_page=20");
  await response.json().then(
    a => {
      //

      a.map((u,i)=>{

        fetch("https://timesca.com/wp-json/wp/v2/media/"+u.featured_media).then(r=>r.json()).then(y=>{

       u.media=y;
            setArts(a);
        }
        );


      });
    setArts(a);
    });
}
  const [arts, setArts] = useState([])
  const [artsMy, setArtsMy] = useState([])
  if(props.start == 'timesca' && artsMy.length==0)  fetchMeData(0);
  if (props.start == 'timescaF'  && arts.length==0)  fetchUserData(0);
  return (
    <>
    <div className=" pt-8 font-sans"  style={{backgroundColor:'#F3F4EF'}}>
      {arts.map((art,i)=>(

        <div onClick={() => openEx(art.link)} key={i} className="cursor-pointer border-gray-900/60 m-8 p-4 rounded-lg bg-gray-50">
         <div className="h3 font-bold mb-4 text-cyan-700" dangerouslySetInnerHTML={{__html:art.title.rendered}}></div>
          {art.media && (
<>
          <img className="w-full" src={art.media.media_details.sizes.medium.source_url
          }  />
<small dangerouslySetInnerHTML={{__html:art.media.caption.rendered}} />
  <div className="mt-2" dangerouslySetInnerHTML={{__html:art.excerpt.rendered.replace('</p>','...</p>')}}/>
</>
          )}
        </div>


      ))}


      {
        artsMy.map((art,i)=>
        (
          <div className="cursor-pointer border-gray-900/60 m-8 p-4 rounded-lg bg-gray-50" key={i}>
            {i+1}
        <img className="w-full" src={art.media_details.sizes.medium.source_url}/>
            {art.slug}
            <br/>
            <small dangerouslySetInnerHTML={{__html:art.caption.rendered}}/>
          </div>
))
      }
    </div>
    </>
  );
}
