import React, {Fragment, useEffect, useRef, useState} from "react";
import {createConnection} from "../services/fetch";
import {getStroke} from "perfect-freehand";
import Opts from "../services/opts";
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Date from "moment";
import {Dialogo, DialogP} from "./dialog";
import {useCloudStorage, useInitData} from "@vkruglikov/react-telegram-web-app";
import {useLoaderData, useParams} from "react-router-dom";
//import {Store} from "../services/store";


const getSvgPathFromStroke = (stroke)=> {
  if (!stroke.length) {
    return '';
  }

  const d = stroke.reduce(
    (acc, [x0, y0], i, arr) => {
      const [x1, y1] = arr[(i + 1) % arr.length];
      acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
      return acc;
    },
    ['M', ...stroke[0], 'Q']
  );

  d.push('Z');
  return d.join(' ');
}
const Pa = (o)=>{
  const [d, setD] = useState('');
  const [str, setStr] = useState(null);

 if(!str && o.sin == o.tm) createConnection().get('strokes/'+o.id,'').then(t=>{
   console.log(o.sin,o.tm);
   setStr(t);

   const line=JSON.parse(t.dots.replace(/}/g,']').replace(/{/g,'['));
   const opts = new Opts(t.width ? t.width : 4,t.type);
  setD(getSvgPathFromStroke(getStroke(line,opts)));
setTimeout(()=>o.in(o.tm+1),400);
 })
  return (
    <>
    {str && (
        <path d={d} fill={str.color}/>

      )
    }
</>
  )
}

export default function Sket() {
  let sor='';
const  read =async (a)=>{
//const so=
 sor += String.fromCharCode.apply(null, a);
}
  const data = useParams();
const id=data.id ? data.id :500;
  const [tm, setTm] = useState(Date.now());
  const [lines, setLines] = useState([]);
  const [line, setLine] = useState({points:[],d:''});
  const [press, setPress] = useState('');
  const [sujet, setSujet] = useState(null);
  const [inte, setInte] = useState(0);
  const [dia, setDia] = useState(false);
  const myCan = useRef(null);

useEffect(() => {

  if (!sujet) {
    /*
      fetch("https://buben-sha.herokuapp.com/api/records/sujet/528?join=objet,strokes")
        // Retrieve its body as ReadableStream
        .then((response) => {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(async ({done, value}) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {

                    controller.close();
                    return;
                  }
                  //
                  await read(value)

                  // Enqueue the next data chunk into our target stream

                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
        }).then((stream) => new Response(stream))
        .then((response) => response.json())
       then((blob) => console.log(blob))


      setSujet(1);


   *
     */


    createConnection().get('sujet/' + id, 'join=objet,strokes&include=size,strokes.id').then(a => {

     console.log('rrrrrrrr')
        setSujet(a);
        //  getObj(a.objet[0])
      }
    );

  }

})

  return (
    <div>

      {
        sujet ?
          (
            <div ref={myCan} className="h-screen bg-gray-50 lock w-screen"

            >
              <svg width={window.outerWidth} height={window.outerHeight}  viewBox={"0 0 "+sujet.size.replace(/,/,' ')}>
                {
               sujet.objet &&   sujet.objet.map((a, i) => (
            <g key={i}>
              { a.strokes.sort((a,b)=>a.id-b.id).map((u,ii)=>
                (

                 <Pa key={ii} tm={ii} id={u.id} in={setInte} sin={inte}/>

                )

              )

              }

            </g>


                  ))

                }

              </svg>
            </div>
          ) : (
            ''
          )

      }


    </div>
  );
}
