import React, {createContext, useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,

  Navigate,
  useLocation
} from "react-router-dom";
import Userfront, { PasswordResetForm } from "@userfront/toolkit/react";
import { useTelegramWebApp } from '@telegram-web-app/react';
//import { TelegramProvider, useTelegram } from "services/teleprovider";

import CalendarC from "./components/calendar";
import Nav from "./components/nav";
import Home from "./components/home";
import Dashboard from "./components/dashboard";

import Team from "./components/team";
import Farm from "./components/farm";
import Esket from "./components/esket";
import Projects from "./components/projects";
Userfront.init("6nz5z4rb");

export const UserContext = createContext(null);
export const tenant="6nz5z4rb";
export default function App() {

  const telegram = useTelegramWebApp();

  async function makeUser(user) {
    await fetch("https://buben-sha.herokuapp.com/api/ufront/records/member", {
      method: "POST",
      body:JSON.stringify(user),
      headers: {
        'x-apikey': 'today',

      }
    }).then(a=>{


    });
  }


  async function fetchUserData(pro) {
    const response = await fetch("https://buben-sha.herokuapp.com/api/records/member?filter=tgId,eq," + pro.id);
    await  response.json().then(
      a=>{

        if(a.records.length>=1) {
          const r=a.records[0];
          setUserw(a.records[0]);
          const uu=pro;
          uu.rights=r.rghts;
          uu.fid=r.id;
          setUser(uu);
        }else{
         setUserw('senf');
          makeUser({name:pro.username,userfront:'tele',tgId:pro.id});

        }
        //  fetchUserFr();

      //  setUser(a.records[0]);
         }
    )

  }


  const [user, setUser] = useState(null);
  const [chat, setChat] = useState(null);
  const [ans, setAns] = useState(null);
  const [userw, setUserw] = useState(null);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
  //  let telApi = window.Telegram.WebApp;//

telegram.WebApp.ready();
    telegram.WebApp.expand();
const store = telegram.WebApp.initDataUnsafe.user ? telegram.WebApp.CloudStorage : null;
   // telegram.WebApp.requestWriteAccess();
  if(!user && telegram.WebApp.initDataUnsafe.user) {


    setChat(telegram.WebApp.initDataUnsafe.chat ? telegram.WebApp.initDataUnsafe.chat.id : telegram.WebApp.chat_instance);
fetchUserData(telegram.WebApp.initDataUnsafe.user);
const u=telegram.WebApp.initDataUnsafe.user;
u.rights=false;u.fid=false;
    setUser(u);

  }

console.log(typeof telegram.WebApp);
if(telegram.WebApp.CloudStorage){
  setAns('a');
store.getItem('key', (s,a)=>setAns(a));
}else{  setAns('b');}



    //   console.log(telegram.WebApp.initDataUnsafe.user);

 //
   });


  return (
    <Router>
<UserContext.Provider value={
  user
  }>

      <div className="lg:container lg:mx-auto md:container md:mx-auto">
<Routes>
          <Route path="/" element={<Esket/>}/>


          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard/>
              </RequireAuth>
            }
          />
        </Routes>
      </div>
</UserContext.Provider>
    </Router>
  );
}


function PasswordReset() {
  return (
    <div>
      <h2>Password Reset</h2>
      <PasswordResetForm />
    </div>
  );
}



function RequireAuth({ children }) {
  let location = useLocation();
  if (!Userfront.tokens.accessToken) {
    // Redirect to the /login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
