import {
  useCloudStorage,
  useInitData
} from '@vkruglikov/react-telegram-web-app';
export function Store() {
  const stTg = useCloudStorage();
  const [initDataUnsafe, initData] = useInitData();

const tg = initDataUnsafe.user ? false : false;
  return {
    getItems(nodes) {
      return tg ? stTg.getItems(nodes) : Promise.resolve(localStorage.getItems(nodes))
    },
    setItems(nodes,values) {
      return tg ? stTg.setItems(nodes,values) : Promise.resolve(localStorage.setItems(nodes,values))
    },
    getItem(node) {
return tg ? stTg.getItem(node) : Promise.resolve(localStorage.getItem(node))
    },
    setItem(node,value) {
      return tg ? stTg.setItem(node,value) : localStorage.setItem(node,value)
    }
  }
}
