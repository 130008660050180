import React, {createContext, useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,

  Navigate,
  useLocation
} from "react-router-dom";
import {
  WebAppProvider,
  MainButton,
  BackButton,
  useCloudStorage,
  useInitData,
  useShowPopup, useWebApp
} from '@vkruglikov/react-telegram-web-app';
import Esket from "./components/esket";
import Projects from "./components/projects";
import {Store} from "./services/store";
import Sket from "./components/sket";
import {createConnection} from "./services/fetch";


export const UserContext = createContext(null);

export default function App1() {

  const webapp = useWebApp();
  const st = useCloudStorage();

  const store = Store();

  const [initDataUnsafe, initData] = useInitData();
  const [user, setUser] = useState(null);
  const [start, setStart] = useState('');
  const [chat, setChat] = useState(null);
  const [ans, setAns] = useState('');
  const [userw, setUserw] = useState(null);
  const [storage, setStorage] = useState(localStorage);

  useEffect(() => {
    if (initDataUnsafe.user) {
      setStart(initDataUnsafe.start_param ? initDataUnsafe.start_param : '');
      setStorage(st);

    }
    // console.log(initDataUnsafe);
//setUser(initDataUnsafe ? initDataUnsafe.user : {username: 'login'});
    webapp.ready();
    webapp.expand();
    /*
        const u = st.getItem('reg').then((b)=>{
    setAns(b);
        });
      */

  });
  const showPopup = useShowPopup();
  const api= createConnection();

  const itemread = (r) => {
    setAns(ans + ' ' + r)
  }
  const handleClick = () => {


    showPopup({
      message: 'Hello, I am ' + ans,
    });
  }


  return (

    <Router>
      <UserContext.Provider value={
        initDataUnsafe ? initDataUnsafe.user : {username: 'login'}
      }>

        <WebAppProvider
          options={{
            smoothButtonsTransition: true,
          }}
        >
          {/** Use components inside provider */}

          {start.match(/timesca/) ? (
            <>

              <Projects start={start}/>
            </>
          ) : (


            <>

              <div className="lg:container lg:mx-auto md:container md:mx-auto">

                <Routes>
                  <Route path="/" element={<Esket/>}/>

                  <Route path="/play/:id" element={<Sket/>} />
                </Routes>

              </div>


            </>
          )}


        </WebAppProvider>

      </UserContext.Provider>
    </Router>
  );
}



