import React, {Fragment, useEffect, useRef, useState} from "react";
import {createConnection} from "../services/fetch";
import {getStroke} from "perfect-freehand";
import Opts from "../services/opts";
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Date from "moment";
import {Dialogo, DialogP} from "./dialog";
import {useCloudStorage, useInitData} from "@vkruglikov/react-telegram-web-app";
//import {Store} from "../services/store";

export default function Esket(props) {
  const store= useCloudStorage()
 // const store = Store();
  const [open, setOpen] = useState(false);
  const [openP, setOpenP] = useState(false);
  const [size,setSize]= useState(0);
  const [brush,setBrush]= useState('pen');
  const [color,setColor]= useState('0x000000');
  const [alpha,setAlpha]= useState(.5);
  const [page, setPage] = useState(null);
  const [draw, setDraw] = useState(false);
  const [tm, setTm] = useState(Date.now());
  const [lines, setLines] = useState([]);
  const [line, setLine] = useState({points:[],d:''});
  const [press, setPress] = useState('');
  const [sujet, setSujet] = useState([]);
  const [inte, setInte] = useState(0);
  const [dia, setDia] = useState(false);
  const myCan = useRef(null);
let temp=0;let se=0;let currentLine =0;
  const [initDataUnsafe, initData] = useInitData();
  if(size==0){
if(initDataUnsafe.user){
  store.getItem('color').then((b)=>{
    setColor(b);
  });
  store.getItem('size').then((b)=>{
    setSize(b);
  });
  store.getItem('brush').then((b)=>{
    setBrush(b);
  });
}else {
  setColor(localStorage.getItem('color'));
  setSize(localStorage.getItem('size') ? localStorage.getItem('size') : 2);
  setBrush(localStorage.getItem('brush'));
}
}
  const getSvgPathFromStroke = (stroke)=> {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }
  const repl=()=>{
    temp+=3;

    lines.map((a,i)=>{
if(i===currentLine){


  a.d=getSvgPathFromStroke(getStroke(a.points.filter(t => t.tm<temp),a.opts));
 // console.log(a.d);
  setPress(temp);
  if(a.end<temp) {
    i==lines.length-1 && clearInterval(se);
    currentLine++ ; temp=0;

//console.log(lines);
  }
}

    })
setLines(lines);
  }
  const replay=()=>{

currentLine=0;
setLines(lines.map(a=>a.d=''));
setTm(Date.now());
 se = setInterval(repl,2);
    //setPress(press+1);
  }
const del=()=>{
setDia(true);

}
  const undo=()=>{
    lines.pop();
    setLines(lines);
    setPress(press+1);
  }

const ptMove=(e)=>{

 if(draw)  {
 setPress(press+1);
 const co = e.width == 0  ? e.pointerType === 'pen' ? 10 : 20 : e.pointerType === 'pen' ? 10 : 1;
line.points.push({x:e.clientX,y:e.clientY,pressure:e.pressure*co,tm:Date.now() - line.startT });

 line.d=  getSvgPathFromStroke(getStroke(line.points,line.opts));
setLine(line);
 }
}
  const fub = (e)=> {
  console.log(e);
    setColor(e.color);
    setSize(e.size);
    setBrush(e.brush);
  }
  const handleDown = (e)=>{
    setPress(e.pointerType);
setDraw(true);
setTm(Date.now());
const totSt=lines[0] ? lines[0].start : Date.now();
    const opts = new Opts(size,brush);
setLine({points:[],d:'',start: Date.now() - totSt ,startT:Date.now(),opts:opts,alpha:alpha,color:color,size:size});


 }
 const delR = ()=>{
    setLines([]);
    setDia(false);
 }
  const handleUp = (e)=>{
    localStorage.getItem('size')


   setTimeout(()=>setDraw(false),200) ;

//console.log(getSvgPathFromStroke(getStroke(line.points)));
line.d= getSvgPathFromStroke(getStroke(line.points,line.opts)) ;
line.end=Date.now() - line.startT;
lines.push(line);


lines.map((a,i)=>{
const points = a.points.map(u=>{

return [Math.floor(u.x*1000)/1000,Math.floor(u.y*1000)/1000,Math.floor(u.pressure*100)/100,u.tm]

})
console.log(points,JSON.stringify({col:a.color,s:a.size,b:a.brush,p:points}).length);
})
setLine(null);
  }
  const handleLeave = (e)=>{
    setDraw(false);
  }
  useEffect(() => {

    if(page !== 'f')   {
      createConnection().get('sujet','filter=user,eq,11&filter=image,cs,mp4&include=id,image,thumb').then(a=> {
setSujet(a.records);
       console.log( a.records.length)
      }
      );

    }
  setPage('f');

  });
  return (
    <div>

      {
        sujet.length > 0 ?
          (
            <div ref={myCan} className="h-screen bg-gray-50 lock w-screen"
                 onPointerMove={ptMove}

              // onPointerLeave={e => handleLeave(e)}
                 onPointerDown={e => handleDown(e)}
                 onPointerUp={e => handleUp(e)}

            >
              <svg width={window.outerWidth} height={window.outerHeight}>
                {
                  lines.map((a, i) => (
                    <path key={i} fill={a.color} fillOpacity={a.alpha} d={a.d}/>


                  ))

                }
                {line &&
                  (
                    <path fill={line.color}  fillOpacity={line.alpha}  d={line.d}/>
                  )
                }
              </svg>
            </div>
          ) : (
            ''
          )

      }

      <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >

                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="absolute -inset-2.5"/>
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                         Esketchos
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">{/* Your content */}</div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {!draw &&
        (
          <div className="fixed bottom-0 right-0 ">

            {lines.length>0 && (
              <>
            <button
              type="button"
              className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => del()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                <path fill="currentColor"
                      d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3zM7 6h10v13H7zm2 2v9h2V8zm4 0v9h2V8z"/>
              </svg>
            </button>


              <button
                type="button"
                className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => replay()}
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="m10 16.5l6-4.5l-6-4.5M22 12c0-5.54-4.46-10-10-10c-1.17 0-2.3.19-3.38.56l.7 1.94c.85-.34 1.74-.53 2.68-.53c4.41 0 8.03 3.62 8.03 8.03s-3.62 8.03-8.03 8.03S3.97 16.41 3.97 12c0-.94.19-1.88.53-2.72l-1.94-.66C2.19 9.7 2 10.83 2 12c0 5.54 4.46 10 10 10s10-4.46 10-10M5.47 3.97c.85 0 1.53.71 1.53 1.5C7 6.32 6.32 7 5.47 7c-.79 0-1.5-.68-1.5-1.53c0-.79.71-1.5 1.5-1.5"/>
                </svg>
              </button>
              <button
                type="button"
                className="rounded-md m-2   focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => undo()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M12.5 8c-2.65 0-5.05 1-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88c3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8"/>
                </svg>
              </button>
              </>
  )}
              <button
                type="button"
                className="rounded-md m-2 text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setOpenP(true)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83l3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75z"/>
                </svg>
              </button>
              <button
                type="button"
                className="rounded-md text-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setOpen(true)}
              >

                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
                  <path fill="#cccccc"
                        d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2M6 7h12v2H6zm0 4h12v2H6zm0 4h12v2H6z"/>
                </svg>
              </button>
          </div>
        )
      }
      <DialogP p={openP} fu={setOpenP} size={size} color={color} brush={brush} fub={fub}/>
<Dialogo p={dia} fu={setDia} del={delR}/>
    </div>
  );
}
